export default {
    name: 'Homeb2b',
    components: {
        SectionHerob2b: () => import('@/components/sections/homeb2b/Herob2b'),
        SectionFeaturesb2b: () => import('@/components/sections/homeb2b/Featuresb2b'),
        SectionHerosmall: () => import('@/components/sections/home/HeroSmall'),
        SectionColImgTxt: () => import('@/components/sections/home/ColImgTxt'),
        SectionTeachers: () => import('@/components/sections/home/Teachers'),
        SectionRating: () => import('@/components/sections/home/Rating'),
        // SectionNewsletter: () => import('@/components/sections/home/Newsletter'),
        BaseScrollButton: () => import('@/components/base/ScrollButton'),
        BaseContact: () => import('@/components/base/Contact'),
        BaseSocialMedia: () => import('@/components/base/SocialMedia'),
        BaseErrorMsg: () => import('@/components/base/ErrorMsg'),
  },
  data() {
    const getStartedLink = 'offer'
    return {
      getStartedLink: getStartedLink,
      hero: [
        {
          title: "Gotta Yoga Pro",
          subtitle: "Invitez vos équipes à cultiver leur bien-être physique et mental",
          text: "Plus de 600 vidéos de yoga et méditation. Cours en direct sur Zoom. Offres personnalisées.",
          btnTxt: "Voir notre offre pro",
          btnLink: getStartedLink,
          image: require("@/assets/img/hero-b2b.jpg")
        }
      ],
      herosmall: [
        {
          title: this.$i18n.t("mission_title"),
          text: this.$i18n.t("mission_text"),
          btnTxt: this.$i18n.t("mission_cta"),
          btnLink: getStartedLink,
          image: require("@/assets/img/herosmall.jpg")
        }
      ],
      colimgtxtb2b: [
        {
          title: "Distinction par Apple",
          text: "Gotta Yoga a été distinguée par Apple comme une des meilleures apps « Forme et Santé », notamment pour le bien-être au travail. Notre équipe fondatrice a également été sélectionnée pour participer à l’Apple Entrepreneur Camp, évènement au cours duquel Apple sélectionne les meilleurs développeurs d’application au niveau mondial et les accompagne. ",
          btnTxt: "Voir notre offre pro",
          btnLink: getStartedLink,
          image: require("@/assets/img/apple-featurings.png")
        }
      ],
      // newsletter: [
      //   {
      //     title: this.$i18n.t("newsletter_title"),
      //     subtitle: this.$i18n.t("newsletter_subtitle"),
      //     btnTxt: this.$i18n.t("newsletter_cta"),
      //     btnLink: '#',
      //     image: require("@/assets/img/meditation.jpeg")
      //   }
      // ],
      errorMessage: {
        title: 'Erreur',
        text: 'Lorem ipsum dolor sit amet consecutor...',
        btns: [
          { txt: 'D\'accord', link: '#check' },
          { txt: 'Pas d\'accord', link: '#disagree' },
        ]
      }
    };
  }
}
