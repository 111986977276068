import firebase from 'firebase/app'
import { language } from '@/common/language'

const apiURL = "https://us-central1-gotta-yoga-pro.cloudfunctions.net/api"
const authenticatedAPIURL = "https://us-central1-gotta-yoga-pro.cloudfunctions.net/api2"
const webhooksURL = "https://us-central1-gotta-yoga-pro.cloudfunctions.net/webhooks"

async function get(path) {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const locale = language()
  const res = await fetch(authenticatedAPIURL + "/" + path + "?lang=" + locale, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
  return res.json()
}

async function post(url, path, body) {
  const res = await fetch(url + "/" + path, {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(body)
  })
  return res.json()
}

export async function getProgram(id) {
  return await get("programs/" + id)
}

export async function getCourse(id) {
  return await get("classes/" + id)
}

export async function getCollections() {
  return await get("collections")
}

export async function postCode(code, email) {
  return await post(apiURL, "useCode", {
    code: code,
    email: email
  })
}

export async function postUseGiftCard(code, email) {
  return await post(apiURL, "useGiftCard", {
    code: code,
    email: email
  })
}

export async function postGiftCard(numberOf1Year, email, language) {
  return await post(apiURL, "checkoutGiftCard", {
    email: email,
    language: language,
    numberOf1Year: numberOf1Year
  })
}

export async function postCharge(source, email, subscriptionId, couponId, webPageId) {
  return await post(apiURL, "charge", {
    source: source,
    email: email,
    subscriptionId: subscriptionId,
    couponId: couponId,
    webPageId: webPageId
  })
}

export async function postChargeCheckout(email, subscriptionId, couponId, webPageId) {
  return await post(apiURL, "checkoutPayment", {
    email: email,
    subscriptionId: subscriptionId,
    couponId: couponId,
    webPageId: webPageId
  })
}

export async function deleteAccount() {
  const token = await firebase.auth().currentUser.getIdToken(true)
  await fetch(webhooksURL + "/delete_account_and_data", {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
}

export async function postSubscriptionStatus(uid) {
  return await post(webhooksURL, "update_subscription_status", {
    uid: uid
  })
}

export async function createStripeCustomerPortalSession(customerId) {
  return await post(webhooksURL, "create_customer_portal_session", {
    customer_id: customerId
  })
}

export async function requestContact(email, firstName, lastName, company, message) {
  const body = {
    email: email,
    company: company,
    message: message,
    firstName: firstName,
    lastName: lastName
  }
  const res = await fetch(webhooksURL + "/contact_request", {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(body)
  })
  return res.json()
}