import {requestContact} from "@/api/requests";

export default {
    name: "Contact",
    components: {
        BaseBtn: () => import("@/components/base/Btn"),
    },
    data() {
        return {
            loading: false,
            firstname: '',
            lastname: '',
            email: '',
            company: '',
            message: '',
            registerError: null,
            success: false
        }
    },
    methods: {
        async register() {
            this.registerError = null
            this.loading = true

            const firstnameError = this.validateField(this.firstname)
            if (firstnameError !== null) {
                this.registerError = this.$i18n.t("FirstnameIsEmpty")
                this.loading = false
                return
            }

            const lastnameError = this.validateField(this.lastname)
            if (lastnameError !== null) {
                this.registerError = this.$i18n.t("LastnameIsEmpty")
                this.loading = false
                return
            }

            const emailError = this.validateField(this.email)
            if (emailError !== null) {
                this.registerError = this.$i18n.t("EmailIsEmpty")
                this.loading = false
                return
            }

            const entrepriseError = this.validateField(this.company)
            if (entrepriseError !== null) {
                this.registerError = "Le champ « Entreprise » ne peut pas être vide."
                this.loading = false
                return
            }

            const messageError = this.validateField(this.message)
            if (messageError !== null) {
                this.registerError = "Le champ « Message » ne peut pas être vide."
                this.loading = false
                return
            }

            const res = await requestContact(this.email, this.firstname, this.lastname, this.company, this.message)
            console.log(res)

            this.success = true
            this.loading = false
        },
        validateField(text) {
            if ((text === null) || (text === "")) {
                return ""
            }
            return null
        }
    }
};
